//! only for stores that need to be used globally, not for every single store
import { hooks as app_flavor_hooks$ } from ":src/modules/whitelabel/app-flavor/store";
import { actions as qr_code_bar$ } from ":src/modules/qr-code-bar/store";

//-- global store logic applied here
import { useStore } from "@nanostores/react";
import type { User } from "firebase/auth";
import { atom } from "nanostores";
import { auth } from "./firebase";

const $user = atom({
  inited: false,
  exist: false,
  initedAndExist: false,
  profile: undefined as User,
});
auth.onAuthStateChanged((user) => {
  console.log("user state changed");
  $user.set({
    inited: true,
    exist: user !== null && user !== undefined,
    initedAndExist: user !== null && user !== undefined,
    profile: user,
  });
});
const hooks = {
  useUser: () => useStore($user),
};
//-- global store logic applied here

export default {
  hooks: {
    global: {
      ...hooks,
    },
    whitelabel: {
      ...app_flavor_hooks$,
    },
  },
  actions: {
    qr_code_bar: {
      ...qr_code_bar$,
    },
  },
};
